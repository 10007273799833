<svelte:options
	customElement={{
		tag: 'business-contact-form'
	}}
/>

<script lang="ts">
	import { onMount } from 'svelte'
	import { basePath } from '../../constants/fwc'

	export let leadsource: string

	const LIMIT_KEY = 'fetch-business-contact-time'
	const LIMIT_INTERVAL = 1000 * 60 * 60 * 12 // 12 Hours
	const TEXT_DARKER_PURPLE = 'text-[#090325]'
	const TEXT_DARK_PURPLE = 'text-[#271C47]'
	const FOCUS_RING_FETCH_YELLOW = 'focus:ring-[#FFA900]'
	const INPUT_CLASSES = `block w-full rounded border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 focus:ring-2 focus:ring-inset ${FOCUS_RING_FETCH_YELLOW} sm:text-sm sm:leading-6`

	let successfulSubmission = false
	let isCurrentlySubmitting = false
	let form: HTMLFormElement
	let handleSubmit: () => void

	if (hasAlreadySubmitted()) {
		successfulSubmission = true
	}

	onMount(() => {
		handleSubmit = () => {
			if (!isCurrentlySubmitting) {
				isCurrentlySubmitting = true
				try {
					if (!form.checkValidity()) {
						form.reportValidity()
					} else {
						form.submit()
						window.localStorage?.setItem(
							LIMIT_KEY,
							`${Date.now() + LIMIT_INTERVAL}`
						)
						trackEvent()
						successfulSubmission = true
					}
				} catch (err) {
					console.error('B2B Contact Form Submission Error', err)
					window.alert(
						'There was an error when submitting the contact form. Please try again.'
					)
				}

				isCurrentlySubmitting = false
			}
		}
	})

	function trackEvent() {
		// @ts-ignore
		const lintrk = window['linktrk']
		lintrk && lintrk('track', { conversion_id: 7203724 })
	}
	function hasAlreadySubmitted() {
		const lastSubmittedTime = window.localStorage?.getItem(LIMIT_KEY)
		return lastSubmittedTime && `${Date.now()}` < lastSubmittedTime
	}
</script>

<div
	style={`background-image: url('${basePath}/assets/projects/website-service/gradient-bg.webp')`}
	class={`w-full rounded-xl bg-cover bg-center px-8 py-10 md:px-14 ${TEXT_DARKER_PURPLE}`}
>
	<div class="mb-8">
		<h2
			class={`text-3xl font-light leading-relaxed md:text-4xl ${TEXT_DARKER_PURPLE}`}
		>
			Partner with Fetch
		</h2>
		<h3 class={`font-semibold md:text-lg ${TEXT_DARK_PURPLE}`}>
			Discover how America's #1 rewards app can drive your brand's growth.
		</h3>
	</div>

	{#if successfulSubmission}
		<p>
			Thanks for contacting us! We've received your submission and will get in
			touch shortly.
		</p>
	{:else}
		<form
			bind:this={form}
			id="embeddable-sfdc-lead-form"
			action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
			method="POST"
			target="dummy-iframe"
		>
			<div class="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
				<div>
					<label for="first_name" class="block">First Name</label>
					<div class="mt-1">
						<input
							type="text"
							name="first_name"
							id="first_name"
							autocomplete="given-name"
							class={INPUT_CLASSES}
							required
							aria-required="true"
						/>
					</div>
				</div>
				<div>
					<label for="last_name" class="block">Last Name</label>
					<div class="mt-1">
						<input
							type="text"
							name="last_name"
							id="last_name"
							autocomplete="family-name"
							class={INPUT_CLASSES}
							required
							aria-required="true"
						/>
					</div>
				</div>
				<div>
					<label for="email" class="block">Email</label>
					<div class="mt-1">
						<input
							type="email"
							name="email"
							id="email"
							autocomplete="email"
							class={INPUT_CLASSES}
							required
							aria-required="true"
						/>
					</div>
				</div>
				<div>
					<label for="company" class="block">Company Name</label>
					<div class="mt-1">
						<input
							type="company"
							name="company"
							id="company"
							autocomplete="organization"
							class={INPUT_CLASSES}
							required
							aria-required="true"
						/>
					</div>
				</div>
				<input
					type="hidden"
					name="00N1Q00000TVtLS"
					value={leadsource}
					id="00N1Q00000TVtLS"
				/>
				<input type="hidden" name="oid" value="00D36000000jVk9" id="oid" />
				<input type="hidden" name="lead_source" value="Web" id="lead_source" />
				<div class="col-span-1 md:col-span-2">
					<div class="ml-auto flex">
						<button
							on:click|preventDefault={handleSubmit}
							class={`right-0 ml-auto mt-6 inline-flex w-full cursor-pointer items-center justify-between rounded bg-[#372763] px-6 py-4 text-white hover:bg-[#890A74] focus:outline-none focus:outline-0 focus:ring-2 ${FOCUS_RING_FETCH_YELLOW} md:w-1/3`}
						>
							<span>Submit</span>
							<svg
								class="inline-flex align-baseline"
								width="50"
								height="25"
								viewBox="0 0 50 25"
								fill="white"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M36.9737 4.07812L35.5255 5.5156L41.2565 11.2145H4.10742V13.2535H41.2565L35.5255 18.9524L36.9737 20.3899L45.1902 12.234L36.9737 4.07812Z"
								/>
							</svg></button
						>
					</div>
				</div>
			</div>
		</form>
	{/if}
</div>

<iframe
	src="/robots.txt"
	title="Dummy iFrame"
	name="dummy-iframe"
	style="visibility: hidden; width: 0px; height:0px;"
/>

<style lang="postcss">
	@import '../../styles/defaults.scss';
</style>
